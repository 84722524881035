<template>
    <b-overlay :show="loading">
        <h3>Ajout d'une marque</h3>
        <b-form @submit.stop.prevent>
            <b-row>
                <b-col cols="6">
                    <b-form-group label="Marque" label-for="input-brand-name">
                        <b-input id="input-brand-name" v-model="brandName" :state="ruleBrandName || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleBrandName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Libellé" label-for="input-wording-name">
                        <b-input id="input-wording-name" v-model="wording" :state="ruleWording || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleWording || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-btn variant="success" class="d-flex ml-auto" @click="confirmBrandUpload">Créer marque</b-btn>
        </b-form>
    </b-overlay>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {},
        data() {
            return {
                brandName: '',
                wording: '',
                showFormError: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleBrandName() {
                return this.brandName !== '';
            },
            ruleWording() {
                return this.wording !== '';
            },
            formValidation() {
                return [this.ruleBrandName, this.ruleWording].every((val) => val === true);
            },
        },
        methods: {
            confirmBrandUpload() {
                if (!this.formValidation) return (this.showFormError = true);

                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir ajouter la marque ?', this.modalContext())
                    .then((confirmed) => {
                        if (confirmed) this.createBrand();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            createBrand() {
                this.loading = true;

                let data = {
                    brand: this.brandName,
                    label: this.wording,
                };

                this.$store
                    .dispatch('app/createBannerBrand', data)
                    .then((res) => {
                        this.$toast(this.successToast('Marque crée avec succès'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la création de la marque'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style></style>

<template>
    <b-tabs>
        <b-tab title="Marque">
            <b-card>
                <form-brand @on-refresh="init"></form-brand>
                <brand-table :brands="brands" @on-refresh="init"></brand-table>
            </b-card>
        </b-tab>
    </b-tabs>
</template>

<script>
    import FormBrand from './brand-form.vue';
    import BrandTable from './table.vue';
    import { mapGetters } from 'vuex';
    export default {
        components: {
            FormBrand,
            BrandTable,
        },
        data() {
            return {
                brands: [],
            };
        },
        computed: {
            ...mapGetters({
                errorToast: 'app/errorToast',
            }),
        },
        mounted() {
            this.$root.toggleGlobalLoading(true);
            this.init();
        },
        methods: {
            init() {
                this.$store
                    .dispatch('app/getBannerBrands')
                    .then((res) => {
                        this.$set(this, 'brands', res.data);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style></style>

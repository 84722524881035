<template>
    <div class="mt-2">
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter" debounce="500"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="brandsForTable" :fields="fields" :per-page="perPage" :current-page="currentPage" @filtered="onTableFiltered">
            <template #cell(Actions)="data">
                <brand-action :key="currentPage + filter + data.item.code" :brand-data="data.item" @on-refresh="$emit('on-refresh')"></brand-action>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </div>
</template>

<script>
    import BrandAction from './brand-action.vue';
    export default {
        components: {
            BrandAction,
        },
        props: {
            brands: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [{ key: 'brand', label: 'Marque' }, { key: 'label', label: 'Libellé' }, 'Actions'],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
            };
        },
        computed: {
            brandsForTable() {
                if (this.filter === '') this.setRows(this.brands);
                return this.brands;
            },
        },
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
        },
    };
</script>

<style></style>

<template>
    <div>
        <b-btn variant="warning" class="p-1 mr-1" @click="onEdit">
            <feather-icon icon="Edit3Icon"></feather-icon>
        </b-btn>
        <b-btn variant="danger" class="p-1 mr-1" @click="onDelete">
            <feather-icon icon="TrashIcon"></feather-icon>
        </b-btn>
        <modal-edit ref="modal-edit" :brand-data="brandData" @on-update="$emit('on-refresh')"></modal-edit>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ModalEdit from './Modals/edit.vue';
    export default {
        components: {
            ModalEdit,
        },
        props: {
            brandData: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        methods: {
            onEdit() {
                this.$refs['modal-edit'].open();
            },
            onDelete() {
                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir supprimer la marque ?', this.modalContext('danger'))
                    .then((confirmed) => {
                        if (confirmed) this.deleteStructure();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            deleteStructure() {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/deleteBrandBanner', this.brandData.code)
                    .then((res) => {
                        this.$toast(this.successToast('La marque a été supprimée avec succès'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        this.$toast(this.successToast('Erreur lors de la suppresion de la marque'));
                        console.error(e);
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style></style>

<template>
    <generic-confirm-modal ref="modal" title="Modification de la marque" cancel-title="Annuler" ok-title="Modifier" @on-accept="updateBrand" @on-cancel="closeModal">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="Marque" label-for="input-brand-name">
                    <b-input id="input-brand-name" v-model="brandName" :state="ruleBrandName || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleBrandName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Libellé" label-for="input-brand-wording">
                    <b-input id="input-brand-wording" v-model="brandWording" :state="ruleBrandWording || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleBrandWording || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            brandData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                showFormError: false,
                brandName: '',
                brandWording: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleBrandName() {
                return this.brandName !== '';
            },
            ruleBrandWording() {
                return this.brandWording !== '';
            },
            formValidation() {
                return [this.ruleBrandName, this.ruleBrandWording].every((val) => val === true);
            },
        },
        mounted() {
            this.brandName = this.brandData.brand;
            this.brandWording = this.brandData.label;
        },
        methods: {
            updateBrand() {
                if (!this.formValidation) return (this.showFormError = true);

                var data = {
                    name: this.brandName,
                    code: this.brandData.code,
                    label: this.brandWording,
                };

                this.loading = true;

                this.$store
                    .dispatch('app/updateBrandBanner', data)
                    .then((res) => {
                        this.$toast(this.successToast('Le template a été modifiée avec succès'));
                        this.$emit('on-refresh');
                        this.closeModal();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la modification du template'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            open() {
                this.$refs.modal.open();
            },
            closeModal() {
                this.$refs.modal.close();
            },
        },
    };
</script>

<style></style>
